import React, { useState } from "react";
import { Helmet } from "react-helmet";
import * as styles from "../../styles/salesforcelead.module.css";
import Layout from "../../components/Layout";
import Recaptcha from "react-recaptcha";
export default function Salesforceform() {
  const [industry, setindustry] = useState("");

  const industrySelected = (e) => {
    setindustry(e);
  };
  // Verified variable
  const [isVerified, setIsVerified] = useState(false);

  // Recaptcha code
  const verifyCallback = (res) => {
    if (res) {
      setIsVerified(true);
    }
  };
  return (
    <Layout>
      <Helmet>
        <title>Salesforce Lead</title>
        <meta name="description" content="Salesforce Lead Form" />
      </Helmet>
      <div className={styles.main}>
        <form
          action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
          method="POST"
          onSubmit={(e) => {
            // e.preventDefault();
            alert("You will receive an email once lead has been created.");
            // window.location.reload();
          }}
        >
          <input hidden name="oid" defaultValue="00D5f000005wFan" />
          <input
            hidden
            name="retURL"
            defaultValue="https://www.digitalbiz.tech/salesforce/lead"
          />

          <div>
            <br />
            <label htmlFor="company">Company </label> <br />
            <input
              required
              id="company"
              maxLength="40"
              name="company"
              size="30"
              type="text"
              className={styles.input}
            />
            <br />
            <label htmlFor="first_name">First Name </label> <br />
            <input
              required
              id="first_name"
              maxLength="40"
              name="first_name"
              size="30"
              type="text"
              className={styles.input}
            />
            <br />
            <label htmlFor="last_name">Last Name </label>
            <br />
            <input
              required
              id="last_name"
              maxLength="80"
              name="last_name"
              size="30"
              type="text"
              className={styles.input}
            />
            <br />
            <label htmlFor="phone">Phone </label>
            <br />
            <input
              required
              id="phone"
              maxLength="40"
              name="phone"
              size="30"
              type="text"
              className={styles.input}
            />
            <br />
            <label htmlFor="email">Email </label>
            <br />
            <input
              required
              id="email"
              maxLength="80"
              name="email"
              size="30"
              type="text"
              className={styles.input}
            />
            <br />
            <label htmlFor="city">City</label>
            <input
              required
              id="city"
              maxLength="40"
              name="city"
              size="20"
              type="text"
              className={styles.input}
            />
            <br />
            <label htmlFor="state">State/Province </label>
            <br />
            <input
              required
              id="state"
              maxLength="20"
              name="state"
              size="30"
              type="text"
              className={styles.input}
            />
            <br />
            <label htmlFor="nol"> Number of Locations</label>
            <input
              required
              id="nol"
              name="nol"
              size="20"
              type="text"
              className={styles.input}
            />
            <br />
            <label htmlFor="revenue">Annual Revenue</label>
            <input
              required
              id="revenue"
              name="revenue"
              size="20"
              type="text"
              className={styles.input}
            />
            <br />
            <label htmlFor="industry">Industry </label>
            <br />
            <div className={styles.selectDiv}>
              <select
                required
                id="industry"
                name="industry"
                className={styles.select}
                defaultValue=""
                onChange={(e) => {
                  industrySelected(e.target.value);
                }}
                onBlur={(e) => {
                  industrySelected(e.target.value);
                }}
              >
                <option value="">--None--</option>
                <option value="Agriculture">Agriculture</option>
                <option value="Apparel">Apparel</option>
                <option value="Banking">Banking</option>
                <option value="Biotechnology">Biotechnology</option>
                <option value="Chemicals">Chemicals</option>
                <option value="Communications">Communications</option>
                <option value="Construction">Construction</option>
                <option value="Consulting">Consulting</option>
                <option value="Education">Education</option>
                <option value="Electronics">Electronics</option>
                <option value="Energy">Energy</option>
                <option value="Engineering">Engineering</option>
                <option value="Entertainment">Entertainment</option>
                <option value="Environmental">Environmental</option>
                <option value="Finance">Finance</option>
                <option value="Food &amp; Beverage">Food &amp; Beverage</option>
                <option value="Government">Government</option>
                <option value="Healthcare">Healthcare</option>
                <option value="Hospitality">Hospitality</option>
                <option value="Insurance">Insurance</option>
                <option value="Machinery">Machinery</option>
                <option value="Manufacturing">Manufacturing</option>
                <option value="Media">Media</option>
                <option value="Not For Profit">Not For Profit</option>
                <option value="Recreation">Recreation</option>
                <option value="Retail">Retail</option>
                <option value="Shipping">Shipping</option>
                <option value="Technology">Technology</option>
                <option value="Telecommunications">Telecommunications</option>
                <option value="Transportation">Transportation</option>
                <option value="Utilities">Utilities</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <br /> <br />
            <Recaptcha
              sitekey="6Lde__EbAAAAAOwSrPdof0BPi5Vh-E4adX4NA0yU"
              render="explicit"
              onloadCallback={() => {
                document.getElementById(
                  "g-recaptcha"
                ).children[0].style.margin = "auto";
              }}
              verifyCallback={verifyCallback}
              expiredCallback={() => {
                setIsVerified(false);
              }}
            />{" "}
            <br />
            <input
              type="submit"
              name="submit"
              className={
                isVerified ? styles.btn : styles.btn + " " + styles.disableBtn
              }
            />
            <br />
            <br />
            <br />
            <br />
            {/* <label htmlFor="lead_source">Lead Source</label>
            <input
              required
              defaultValue="Web"
              id="lead_source"
              name="lead_source"
              size="20"
              type="text"
              className={styles.input + " " + styles.leadSource}
            /> */}
            <label htmlFor="lead_source" className={styles.leadSource}>
              Lead Source
            </label>
            <br />
            <div className={styles.selectDiv + " " + styles.leadSource}>
              <select
                required
                id="lead_source"
                name="lead_source"
                className={styles.select}
                defaultValue="Web"
              >
                <option value="">--None--</option>
                <option value="Web">Web</option>
              </select>
            </div>
            <br />
          </div>
        </form>
      </div>
    </Layout>
  );
}
